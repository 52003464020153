import { getPublic, sign } from "@toruslabs/eccrypto";
import { post, setLogLevel } from "@toruslabs/http-helpers";
import { decryptData, encryptData, keccak256 } from "@toruslabs/metadata-helpers";
import { LogLevelDesc } from "loglevel";
import { io, type Socket } from "socket.io-client";

import { ISecurePubSubOptions, SetDataBody } from "./interfaces";
import log from "./log";

export class SecurePubSub {
  options: ISecurePubSubOptions;

  SOCKET_CONN: Socket | null = null;

  constructor(options: ISecurePubSubOptions = {}) {
    this.options = options;
    this.options.timeout = options.timeout || 600; // 10 mins is default timeout
    this.options.serverUrl = options.serverUrl || "https://broadcast-server.tor.us";
    this.options.enableLogging = options.enableLogging || false;
    this.options.namespace = options.namespace || "";
    if (this.options.enableLogging) log.enableAll();
    else log.disableAll();
  }

  static setLogLevel(level: LogLevelDesc) {
    log.setLevel(level);
    setLogLevel(level);
  }

  async publish(topic: string, message: string): Promise<void> {
    const topicPrivKey = keccak256(Buffer.from(topic, "utf8"));
    const encryptedData = await encryptData(topicPrivKey.toString("hex"), message);
    const signature = await sign(topicPrivKey, keccak256(Buffer.from(encryptedData, "utf8")));
    const fetchBody: SetDataBody = {
      key: getPublic(topicPrivKey).toString("hex"), // already padded
      data: encryptedData,
      signature: signature.toString("hex"),
      timeout: this.options.timeout,
      namespace: this.options.namespace,
    };
    return post(`${this.options.serverUrl}/channel/set`, fetchBody);
  }

  async subscribe(topic: string): Promise<string> {
    let isPromisePending = true;
    const topicPrivKey = keccak256(Buffer.from(topic, "utf8"));
    const topicPubKey = getPublic(topicPrivKey).toString("hex"); // already padded
    const currentSocketConnection = this.getSocketConnection();
    if (currentSocketConnection.connected) {
      log.debug("already connected with socket");
      currentSocketConnection.emit("check_auth_status", topicPubKey, { namespace: this.options.namespace });
    } else {
      currentSocketConnection.once("connect", () => {
        log.debug("connected with socket");
        currentSocketConnection.emit("check_auth_status", topicPubKey, { namespace: this.options.namespace });
      });
    }

    const reconnect = () => {
      currentSocketConnection.once("connect", async () => {
        log.debug("connected with socket using reconnect");
        if (isPromisePending) currentSocketConnection.emit("check_auth_status", topicPubKey, { namespace: this.options.namespace });
      });
    };

    const visibilityListener = () => {
      if (!isPromisePending) document.removeEventListener("visibilitychange", visibilityListener);
      // if not connected, then wait for connection and ping server for latest msg.
      if (!currentSocketConnection.connected && document.visibilityState === "visible") {
        reconnect();
      }
    };

    const disconnectListener = () => {
      log.debug("socket disconnected", isPromisePending);
      if (isPromisePending) {
        log.error("socket disconnected unexpectedly, reconnecting socket");
        reconnect();
      } else {
        currentSocketConnection.removeListener("disconnect", disconnectListener);
      }
    };

    currentSocketConnection.on("disconnect", disconnectListener);

    const returnPromise = new Promise<string>((resolve, reject) => {
      const listener = async (ev: string) => {
        try {
          const decData = await decryptData(topicPrivKey.toString("hex"), ev);
          log.info("got data", decData);
          resolve(decData as string);
        } catch (error) {
          log.error(error);
          reject(error);
        } finally {
          isPromisePending = false;
          document.removeEventListener("visibilitychange", visibilityListener);
        }
      };
      log.info("listening to", `${topicPubKey}_success`);
      currentSocketConnection.once(`${topicPubKey}_success`, listener);
    });

    if (typeof document !== "undefined") document.addEventListener("visibilitychange", visibilityListener);
    return returnPromise;
  }

  public cleanup() {
    if (this.SOCKET_CONN) {
      this.SOCKET_CONN.disconnect();
      this.SOCKET_CONN = null;
    }
  }

  private getSocketConnection(): Socket {
    if (this.SOCKET_CONN) return this.SOCKET_CONN;
    const localSocketConnection = io(this.options.serverUrl, {
      transports: ["websocket", "polling"], // use WebSocket first, if available
      withCredentials: true,
      reconnectionDelayMax: 10000,
      reconnectionAttempts: 10,
    });

    localSocketConnection.on("connect_error", (err) => {
      // revert to classic upgrade
      localSocketConnection.io.opts.transports = ["polling", "websocket"];
      log.error("connect error", err);
    });
    localSocketConnection.on("connect", async () => {
      const { engine } = localSocketConnection.io;
      log.debug("initially connected to", engine.transport.name); // in most cases, prints "polling"
      engine.once("upgrade", () => {
        // called when the transport is upgraded (i.e. from HTTP long-polling to WebSocket)
        log.debug("upgraded", engine.transport.name); // in most cases, prints "websocket"
      });
      engine.once("close", (reason) => {
        // called when the underlying connection is closed
        log.debug("connection closed", reason);
      });
    });

    localSocketConnection.on("error", (err) => {
      log.error("socket errored", err);
      localSocketConnection.disconnect();
    });

    this.SOCKET_CONN = localSocketConnection;
    return this.SOCKET_CONN;
  }
}
