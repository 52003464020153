import { SafeEventEmitter } from "@toruslabs/openlogin-jrpc";
const filterNoop = () => true;
const internalEvents = ["newListener", "removeListener"];
const externalEventFilter = (name: string) => !internalEvents.includes(name);

interface EventEmitterProxyOpts {
  eventFilter?: ((name: string) => boolean) | string;
}

function getRawListeners<T extends SafeEventEmitter>(eventEmitter: T, name: string | symbol) {
  // prefer native
  return typeof eventEmitter.rawListeners !== "undefined" ? eventEmitter.rawListeners(name) : eventEmitter.listeners(name);
}

export default function createEventEmitterProxy<T extends SafeEventEmitter>(initialTarget: T, opts?: EventEmitterProxyOpts): T {
  // parse options
  const finalOpts = opts || {};
  let eventFilter = finalOpts.eventFilter || filterNoop;
  if (typeof eventFilter === "string" && eventFilter === "skipInternal") eventFilter = externalEventFilter;
  if (typeof eventFilter !== "function") throw new Error("createEventEmitterProxy - Invalid eventFilter");

  let target = initialTarget;

  let setTarget = (newTarget: T) => {
    const oldTarget = target;
    target = newTarget;

    oldTarget
      .eventNames()
      .filter(eventFilter as (name: string | symbol) => boolean)
      .forEach((name: string | symbol) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getRawListeners(oldTarget, name).forEach((handler) => newTarget.on(name, handler as (...args: any[]) => void));
      });

    // remove old listeners
    oldTarget.removeAllListeners();
  };

  const proxy = new Proxy<T>({} as T, {
    get: (_, name: "setTarget" | keyof SafeEventEmitter) => {
      // override `setTarget` access
      if (name === "setTarget") return setTarget;
      return target[name as keyof SafeEventEmitter];
    },
    set: (_, name: "setTarget" | keyof SafeEventEmitter, value) => {
      // allow `setTarget` overrides
      if (name === "setTarget") {
        setTarget = value;
        return true;
      }
      target[name as keyof SafeEventEmitter] = value;
      return true;
    },
  });

  return proxy;
}
